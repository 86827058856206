var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-visaoGeral"},[_c('div',{},[_vm._m(0),_c('VueSlickCarousel',_vm._b({},'VueSlickCarousel',_vm.settings,false),[_c('div',{staticClass:"visaoGeral-items"},[_c('h3',[_vm._v("Projeto Total")]),_c('div',{staticClass:"barraProgresso"},[_c('div',{staticClass:"progresso",class:{
              danger: _vm.progressTotal(_vm.$store.state.projectModule.projectTasks) < 30,
              warning: _vm.progressTotal(_vm.$store.state.projectModule.projectTasks) >= 30 && _vm.progressTotal(_vm.$store.state.projectModule.projectTasks) <= 60,
              success: _vm.progressTotal(_vm.$store.state.projectModule.projectTasks) > 60,
            },style:({ width: `${_vm.progressTotal(_vm.$store.state.projectModule.projectTasks)}%` })},[_vm._v(" "+_vm._s(_vm.progressTotal(_vm.$store.state.projectModule.projectTasks))+"% ")])])]),_vm._l((_vm.tasksBySector),function(taskBySector){return _c('div',{key:taskBySector.sector,staticClass:"visaoGeral-items"},[_c('h3',[_vm._v(" "+_vm._s(taskBySector.sector)+" ")]),_c('div',{staticClass:"barraProgresso"},[_c('div',{staticClass:"progresso",class:{
              danger: _vm.progressTotal(taskBySector.tasks) < 30,
              warning:
                _vm.progressTotal(taskBySector.tasks) >= 30 &&
                _vm.progressTotal(taskBySector.tasks) <= 60,
              success: _vm.progressTotal(taskBySector.tasks) > 60,
            },style:({ width: `${_vm.progressTotal(taskBySector.tasks)}%` })},[_vm._v(" "+_vm._s(_vm.progressTotal(taskBySector.tasks))+"% ")])])])})],2)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"visaoGeral-titulo"},[_c('h2',[_vm._v("Visão geral")])])
}]

export { render, staticRenderFns }