<template>
  <div>
    <div v-if="loading" class="skeleton">
      <!-- Adicione uma skeleton aqui -->
    </div>
    <template v-else>
      <div class="row container-painel">
        <div class="column small-12 large-9">
          <div class="painel-titulo">
            <h1>Olá! Boas vindas ao seu projeto!</h1>
            <router-link to="/painel/checklist"> Meu checklist </router-link>
          </div>
        </div>
        <div class="column small-12 large-3">
          <div class="painel-goLive">
            <p>Faltam <b>12 dias</b> para o Go-Live*</p>
          </div>
          <div class="painel-goLive-status">
            Status:
            <span><img src="@/assets/img/check.svg" alt="" /> Em dia</span>
          </div>
        </div>
      </div>
      <PanelTimeline />
      <div class="row">
        <div class="column large-9">
          <PanelOverview />
          <PanelGantChart />
          <PanelServicesPartner 
            v-if="servicesPartners.length"
            :servicesPartners="servicesPartners" 
            :contentCategory="contentCategory"
            @changeContentCategory="changeContentCategory"
          />
          <PanelResposible 
            v-if="$store.state.projectModule.projectsTeam.length" 
          />
        </div>
        <div class="column large-3">
          <PanelNotifications />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import PanelTimeline from '../components/panel/PanelTimeline.vue';
import PanelOverview from '../components/panel/PanelOverview.vue';
import PanelGantChart from '../components/panel/PanelGantChart.vue';
import PanelServicesPartner from '../components/panel/PanelServicesPartner.vue';
import PanelResposible from '../components/panel/PanelResposible.vue'
import PanelNotifications from '../components/panel/PanelNotifications.vue';

export default {
  components: {
    PanelTimeline,
    PanelOverview,
    PanelGantChart,
    PanelServicesPartner,
    PanelResposible,
    PanelNotifications
  },
  data() {
    return {
      loading: false,
      servicesPartners: [],
      contentCategory: [
        { 
          label: 'Rankeamento orgânico', 
          selected: true, 
          available: true,
          className: ''
        },
        { 
          label: 'design', 
          selected: false, 
          available: true,
          className: ''
        }
      ],
    }
  },
  computed: {
    currentCategory() {
      return this.contentCategory.find(category => category.selected);
    }
  }, 
  methods: {
    async fetchViewPanel() {
      this.loading = true;
      await Promise.all([
        this.fetchProject(),
        this.fetchTasksProject(),
        this.fetchServicesAndPartners(),
        this.fetchProjectNotifications()
      ]);
      this.loading = false;
    },
    fetchTasksProject() {
      return this.$store.dispatch(
        'fetchProjectTasksById', 
        this.$store.state.projectModule.currentProject.id
      );
    },
    fetchProject() {
      return this.$store.dispatch(
        'fetchProjectById', 
        this.$store.state.projectModule.currentProject.id
      );
    },
    fetchProjectsTeam() {
      return this.$store.dispatch(
        'fetchProjectsTeam', 
        this.$store.state.projectModule.currentProject.id
      );
    },
    async fetchServicesAndPartners() {
      const { data } = await this.$axios.get('/servicos-parceiros?limit=10&page=1');
      this.servicesPartners = data.data;
    },
    fetchContentByCategory({ label }) {
      this.$store.dispatch('fetchContentByCategory', label);
    },
    fetchProjectNotifications() {
      return this.$store.dispatch(
        'fetchProjectUpdates', 
        this.$store.state.projectModule.currentProject.id
      );
    },
    changeContentCategory({ newOptions, targetOption }) {
      this.contentCategory = newOptions;
      this.fetchContentByCategory(targetOption);
    }
  },  
  created() {
    this.fetchViewPanel();
    this.fetchProjectsTeam();
    this.fetchContentByCategory(this.currentCategory);
  }
};
</script>
<style>
</style>
<style scoped>
.container-painel {
  margin-bottom: 32px;
}
.painel-titulo {
  background: #f5f5f5;
  padding: 35px 55px 35px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
}
.painel-titulo h1 {
  font-size: 28px;
  color: #1c1c1c;
  font-family: "Rubik", sans-serif;
  margin-bottom: 0;
  line-height: 1.1;
}
.painel-titulo a {
  height: 34px;
  max-width: 171px;
  width: 100%;
  border-radius: 2px;
  background: #e85128;
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.painel-goLive {
  background: #e85128;
  height: 73px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 5px;
}
.painel-goLive p {
  margin-bottom: 0;
}
.painel-goLive-status {
  background-color: #f5f5f5;
  border-radius: 2px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  color: #000000;
  gap: 10px;
}
.painel-goLive-status span {
  font-size: 12px;
  font-weight: bold;
  color: #4aae51;
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>