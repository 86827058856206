<template>
  <div class="row container-timeline">
    <div class="column small-12">
      <div class="timeline-titulo">
        <h2>Timeline de etapas</h2>
      </div>
      <div>
        <div class="linhaDoTempo" :style="{ background: backgroundGradiente }" v-if="backgroundGradiente" />
        <div class="container-timeline-items">
          <div 
            v-for="etapa in  projectSteps" 
            :key="etapa.id" 
            :class="{concluida: etapa.concluido}" 
            :id="etapa.andamento" 
            class="timeline-items"
          >
            <img :src="require(`@/assets/img/timeline/${etapa.icone}.svg`)" alt="">  
            <template v-if="etapa.dataLimite == null">
                a definir
              </template>
              <template v-else>
                <strong> {{ returnDayMonth(etapa.dataLimite) }}</strong>
              </template>
            <div>{{ etapa.nome }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    projectSteps() {
      return this.$store.state.projectModule.project.etapas;
    },  
    porcentagemBarra() {
      let contador = 0;
      const qtdEtapas = 100 / Object.keys(this.projectSteps).length;

      this.projectSteps.filter((etapa) => {
        if(etapa.concluido == true) {
          contador += 1;
        }
      });

      const porcentagem = qtdEtapas * contador;

      return Math.round(porcentagem);
    },
    backgroundGradiente() {
      return `linear-gradient(90deg, rgba(217,217,217,1) ${this.porcentagemBarra}%, rgba(148,148,148,1) ${this.porcentagemBarra}%, rgba(0,0,0,1) 35%, rgba(92,92,92,1) 100%)` 
    }
  },
  methods: {
    returnDayMonth(date) {
      date  = new Date(date);
      return `${date.getUTCDate()}/${date.getUTCMonth()+1}`;
    },
  }
}
</script>

<style scoped>
.timeline-titulo {
  margin-bottom: 20px;
}
.timeline-titulo h2 {
  font-size: 20px;
  color: #1c1c1c;
  font-family: 'Rubik', sans-serif;
}
.linhaDoTempo {
  height: 1px;
  width: 100%;
  margin-bottom: 14px;
}
.timeline-items {
  border: 1px solid #EAEAEA;
  width: 85px;
  min-height: 118px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 6px;
  font-size: 11px;
  word-wrap: break-word;
}

.timeline-items div {
  margin-top: 8px;
}
.timeline-items::before {
  width: 6px;
  height: 6px;
  background: #5C5C5C;
  content: '';
  border-radius: 10px;
  display: block;
  position: absolute;
  left: 50%;
  top: -15px;
  transform: translate(-50%,-50%);
}
#em_dia::before {
  background: #4AAE51;
}
#atencao::before {
  background: #F9A825;
}
#em_atraso::before {
  background: #D22E2E;
}
.timeline-items img {
  width: 17px;
  height: 16px;
  margin-bottom: 20px;
}
.container-timeline-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-timeline {
  margin-bottom: 32px;
}
.concluida {
  border-color: #EAEAEA;
  background: #F5F5F5;
  color: #A3A3A3;
}
.concluida img {
  opacity: 0.3;
}
</style>