<template>
  <div class="container-visaoGeral">
    <div class="">
      <div class="visaoGeral-titulo">
        <h2>Visão geral</h2>
      </div>
      <VueSlickCarousel v-bind="settings">
        <div class="visaoGeral-items">
          <h3>Projeto Total</h3>
          <div class="barraProgresso">
            <div
              :style="{ width: `${progressTotal($store.state.projectModule.projectTasks)}%` }"
              :class="{
                danger: progressTotal($store.state.projectModule.projectTasks) < 30,
                warning: progressTotal($store.state.projectModule.projectTasks) >= 30 && progressTotal($store.state.projectModule.projectTasks) <= 60,
                success: progressTotal($store.state.projectModule.projectTasks) > 60,
              }"
              class="progresso"
            >
              {{ progressTotal($store.state.projectModule.projectTasks) }}%
            </div>
          </div>
        </div>
        <div
          v-for="taskBySector in tasksBySector"
          :key="taskBySector.sector"
          class="visaoGeral-items"
        >
          <h3>
            {{ taskBySector.sector }}
          </h3>
          <div class="barraProgresso">
            <div
              :style="{ width: `${progressTotal(taskBySector.tasks)}%` }"
              :class="{
                danger: progressTotal(taskBySector.tasks) < 30,
                warning:
                  progressTotal(taskBySector.tasks) >= 30 &&
                  progressTotal(taskBySector.tasks) <= 60,
                success: progressTotal(taskBySector.tasks) > 60,
              }"
              class="progresso"
            >
              {{ progressTotal(taskBySector.tasks) }}%
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    };
  },
  computed: {
    tasksBySector() {
      const sectors = [];

      this.$store.state.projectModule.projectTasks.forEach((item) => {
        if (!sectors.includes(item.setorResponsavel.nome)) {
          sectors.push(item.setorResponsavel.nome);
        }
      });

      const tasksGroupBySector = this.$store.state.projectModule.projectTasks
          .reduce((accumulator, currentValue) => {
            (accumulator[currentValue.setorResponsavel.nome] = accumulator[currentValue.setorResponsavel.nome] || []).push(currentValue)
            return accumulator;
          }, {})

      const taskBySector = sectors.map(sector => {
        return {
          sector,
          tasks: tasksGroupBySector[sector]
        }
      });

      return taskBySector;
    },
  },
  methods: {
    progressTotal(data) {
      let progress = 100;
      const amountOfTasks = data.length;

      if (amountOfTasks) {
        const taskFinished = data.filter((tarefa) => tarefa.statusAtual.nome == "Concluído");
  
        progress = ((taskFinished.length * 100) / amountOfTasks);
      }


      return Math.round(progress);
    },
  },
};
</script>

<style scoped>
.danger {
  background: #d22e2e;
}
.warning {
  background: #f9a825;
}
.success {
  background: #4aae51;
}
.container-visaoGeral {
  margin-bottom: 32px;
}
.visaoGeral-titulo {
  margin-bottom: 14px;
}
.visaoGeral-titulo h2 {
  font-size: 20px;
  color: #1c1c1c;
  font-family: "Rubik", sans-serif;
}
.visaoGeral-scroll {
  max-width: 100%;
}
.container-visaoGeral-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
}
.visaoGeral-items h3 {
  font-size: 16px;
  color: #1c1c1c;
  font-family: "Rubik", sans-serif;
}
.visaoGeral-items {
  border: 1px solid #eaeaea;
  min-height: 116px;
  max-width: 212px;
  width: 100%;
  padding: 15px 10px;
}
.barraProgresso {
  max-width: 194px;
  width: 100%;
  height: 36px;
  border: 1px solid #ececec;
  padding: 5px 6px;
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.progresso {
  height: 26px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  padding: 6px 10px;
}
</style>