<template>
  <div class="panel-servicespartner">
    <div class="containerServicosConteudo">
      <div class="container-servicos">
        <h2 class="servicos-titulo">Serviços e parceiros</h2>
        <div class="containerItemServicos">
          <VueSlickCarousel v-bind="settings">
            <div 
              v-for="({ titulo, descricao, imagem, link }, index) in servicesPartners" 
              :key="`servicePartner${index}`" 
              class="itemServicos"
            >
              <a :href="link" target="_blank" class="itemServicos-foto">
                <img 
                  v-if="imagem"
                  :src="imagem" 
                  :alt="titulo"
                  width="156"
                  height="56"
                />
                <img 
                  v-else
                  src="@/assets/img/panel-services-partner/default-img.jpg" 
                  :alt="titulo"
                  width="156"
                  height="56"
                />
              </a>
              <a :href="link" target="_blank" class="itemServicos-titulo">
                {{ titulo }}
              </a>
              <p class="itemServicos-paragrafo">
                {{ descricao }}
              </p>
            </div>
          </VueSlickCarousel>
        </div>
      </div>

      <!-- CONTEUDOS -->
      <div class="container-conteudo">
        <div class="container-conteudo-flex">
          <h2 class="conteudo-titulo">Conteúdos</h2>
          <div class="panel-servicespartner__filter">
            <p class="panel-servicespartner__filter-label">
              Filtrar por:
            </p>
            <CommonSelect 
              :options="contentCategory" 
              @change="changeContentCategory"
              class="panel-servicespartner__category-filter"
            >
              <template #currentOptionImage>
                <img src="@/assets/img/icon-select.svg" alt="">
              </template>
            </CommonSelect>
          </div>
        </div>
        <div class="containerItemConteudo">
          <template v-if="$store.state.contentModule.contents.length">
            <img
              class="gradiente"
              src="@/assets/img/gradient-branco.png"
              alt=""
            />
            <VueSlickCarousel 
              v-bind="settings"
              :key="contentSlideKey"
              >
              <div 
                v-for="({ categoria, descricao, imagem, link }, index) in $store.state.contentModule.contents" 
                :key="`content${index}`" 
                class="itemConteudo"
              >
                <a :href="link" target="_blank" class="itemServicos-foto">
                  <img 
                    v-if="imagem"
                    :src="imagem" 
                    :alt="categoria"
                    width="156"
                    height="56"
                  />
                  <img 
                    v-else
                    src="@/assets/img/panel-services-partner/default-img.jpg" 
                    :alt="categoria"
                    width="156"
                    height="56"
                  />
                </a>
                <h3 class="itemConteudo-titulo">
                  {{ categoria }}
                </h3>
                <p class="itemConteudo-paragrafo">
                  {{ descricao }}
                </p>
              </div>
            </VueSlickCarousel>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import CommonSelect from '@/components/common/CommonSelect.vue';

export default {
  components: {
    VueSlickCarousel,
    CommonSelect
  },
  props: {
    servicesPartners: {
      type: Array,
      required: true
    },
    contentCategory: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      contentSlideKey: 1,
      settings: {
        dots: false,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2.5,
        slidesToScroll: 3,
      },
    };
  },
  watch: {
    '$store.state.contentModule.loading': function(newValue) {
      if (!newValue) {
        /**
         * força o update do slide de conteudo ao mudar de categoria,
         * pois pode acontecer da categoria selecionada ter menos
         * conteudo para exibir do que a atual fazendo com que o slide 
         * bug. Segue ilustração: https://i.imgur.com/zgr4TO9.png
         */
        this.contentSlideKey += 1;
      }
    }
  },
  methods: {
    changeContentCategory(payload) {
      this.$emit('changeContentCategory', payload);
    },
  }
};
</script>
<style>
/* ESTILOS SELECT */
.panel-servicespartner .select {
  z-index: 2;
}
.panel-servicespartner .select__current-value {
  height: 14px;
}
/******************/

/* ESTILOS SLIDE */
.panel-servicespartner .slick-slider,
.panel-servicespartner .slick-list {
  height: 100%;
}
.panel-servicespartner .slick-slider * {
  -webkit-user-drag: none;
}
/*****************/
</style>
<style scoped>
.containerServicosConteudo {
  display: flex;
  gap: 15px;
  margin-bottom: 64px;
}
.servicos-titulo,
.conteudo-titulo {
  font-size: 20px;
  color: #1c1c1c;
  font-family: "Rubik", sans-serif;
}
.servicos-titulo {
  margin-bottom: 18px;
}
.conteudo-titulo {
  margin-bottom: 0;
}
.containerItemServicos,
.containerItemConteudo {
  position: relative;
  flex: 1 0;
  padding: 13px;
  padding-right: 0px;
  border: 1px solid #eaeaea;
}
.itemServicos-foto,
.itemConteudo-foto {
  display: block;
  margin-bottom: 5px;
  background: #C4C4C4;
}
.container-servicos,
.container-conteudo {
  display: flex;
  flex-direction: column;
  max-width: 438px;
}
.itemServicos-titulo,
.itemConteudo-titulo {
  display: block;
  font-size: 13px;
  color: #1c1c1c;
  font-weight: bold;
  margin-bottom: 0px;
}
.itemServicos-paragrafo,
.itemConteudo-paragrafo {
  font-size: 12px;
  color: #1c1c1c;
  margin-bottom: 0px;
}
.itemServicos,
.itemConteudo {
  max-width: 156px;
  width: 100%;
}
.container-conteudo-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.panel-servicespartner__filter {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 277px;
}
.panel-servicespartner__filter-label {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  color: #5C5C5C;
}
.panel-servicespartner__category-filter {
  width: 100%;
  max-width: 206px;
}
.gradiente {
  z-index: 1;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  user-select: none;
  -webkit-user-drag: none;
}
.select-box-value::before {
  content: url('../../assets/img/icon-select.svg');
}
</style>
<style>
.container-conteudo .select-box-value {
  display: flex;
  align-items: center;
  gap: 8px;
}
.container-conteudo .select-box-value::before {
  content: url('../../assets/img/icon-select.svg');
}
</style>