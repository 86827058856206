<template>
  <div class="container-gantt">
    <div class="">
      <div class="gantt-titulo">
        <h2>Visão geral do desenvolvimento</h2>
      </div>
      <div class="grid-ganttScroll">
        <img class="gradiente" src="@/assets/img/gradient-branco.png" alt="" />
        <div 
          ref="refGridGantt"
          class="grid-gantt" 
          id="scrollHorizontal"
        >
          <template v-for="gant in gantt">
            <span
              class="grid-gantt-items-dia"
              v-for="(dia, i) in gant.qtdDia"
              :key="gant.nome + i"
            >
              {{ dia }} {{ gant.nome }}
            </span>
          </template>
          <PanelGantChartTasks
            v-for="(task, index) in projectsTasks"
            :key="index"
            :task="task"
            :index="index"
            :ref="`refTask${index}`"
            :style="{
              'grid-column': `${getDayOfYear(task.dataInicio)}/${
                getDayOfYear(task.dataFim) + 1
              }`,
            }"
            class="grid-gantt-items-tarefas"
          />
        </div>
        <div class="legenda">
          Legenda:
          <div class="emDia">Em dia</div>
          <div class="atencao">Atenção</div>
          <div class="emAtraso">Em atraso</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PanelGantChartTasks from './PanelGantChartTasks.vue';
export default {
  name: "PanelGantChart",
  components: {
    PanelGantChartTasks,
  },
  computed: {
    months() {
      return [
        {
          nome: "jan",
          qtdDia: 31,
          numMes: 0,
        },
        {
          nome: "fev",
          qtdDia: 28,
          numMes: 1,
        },
        {
          nome: "mar",
          qtdDia: 31,
          numMes: 2,
        },
        {
          nome: "abr",
          qtdDia: 30,
          numMes: 3,
        },
        {
          nome: "maio",
          qtdDia: 31,
          numMes: 4,
        },
        {
          nome: "jun",
          qtdDia: 30,
          numMes: 5,
        },
        {
          nome: "jul",
          qtdDia: 31,
          numMes: 6,
        },
        {
          nome: "ago",
          qtdDia: 31,
          numMes: 7,
        },
        {
          nome: "set",
          qtdDia: 30,
          numMes: 8,
        },
        {
          nome: "out",
          qtdDia: 31,
          numMes: 9,
        },
        {
          nome: "nov",
          qtdDia: 30,
          numMes: 10,
        },
        {
          nome: "dez",
          qtdDia: 31,
          numMes: 11,
        },
      ]
    },
    projectsTasks() {
      return this.$store.state.projectModule.projectTasks;
    },
    gantt() {
      const date = new Date();

      this.months.forEach((mes) => {
        if (date.getFullYear() % 400 === 0) {
          if (mes.nome == "fev") {
            mes.qtdDia = 29;
          }
          return this.months;
        } else if (
          (date.getFullYear() % 400) % 4 === 0 &&
          !(date.getFullYear() % 400) % 100 === 0
        ) {
          if (mes.nome == "fev") {
            mes.qtdDia = 29;
          }
          return this.months;
        }
      });

      return this.months;
    },
  },
  methods: {
    getDayOfYear(date) {
      date = new Date(date);

      const timestamp1 = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      const timestamp2 = Date.UTC(date.getFullYear(), 0, -1, 0, 0, 0, 0);

      const differenceInMilliseconds = timestamp1 - timestamp2;

      const differenceInDays = differenceInMilliseconds / 1000 / 60 / 60 / 24;

      if (date.getFullYear() % 400 === 0) {
        return differenceInDays > 366 ? 1 : differenceInDays;
      } else if (
        (date.getFullYear() % 400) % 4 == 0 &&
        !(date.getFullYear() % 400) % 100 == 0
      ) {
        return differenceInDays > 366 ? 1 : differenceInDays;
      } else {
        return differenceInDays > 365 ? 1 : differenceInDays;
      }
    },
    goToFirstTask() {
      this.$refs.refGridGantt.scrollLeft =( 
        this.$refs.refTask0[0].$el.offsetLeft -
        this.$refs.refGridGantt.offsetLeft -
        90
      );
    }
  },
  mounted() {
    this.goToFirstTask();
  }
};
</script>

<style scoped>
.gantt-titulo {
  margin-bottom: 14px;
}
.gantt-titulo h2 {
  font-size: 20px;
  color: #1c1c1c;
  font-family: "Rubik", sans-serif;
}
.container-gantt {
  margin-bottom: 64px;
  position: relative;
}
.grid-ganttScroll {
  max-width: 100%;
  border: 1px solid #eaeaea;
  padding: 20px 10px;
}
.grid-gantt {
  overflow: auto;
  white-space: nowrap;
  display: grid;
  grid-template-columns: repeat(365, 45px);
  min-height: 155px;
  border: 1px solid #d9d9d9;
  border-top-width: 0px;
  background-image: url(../../assets/img/gantt/coluna-gantt.png);
  background-repeat: repeat;
  background-attachment: local;
}

.grid-gantt-items-tarefas {
  border: 1px solid #000;
  font-size: 14px;
  padding: 4px;
  border-radius: 20px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  gap: 3px;
  position: relative;
  cursor: pointer;
  background: #fff;
}
.grid-gantt-items-tarefas p {
  margin-bottom: 0px;
}
.grid-gantt-items-p {
  display: flex;
  justify-content: flex-start;
  padding: 6px 0;
  margin-bottom: 0px;
  margin-right: 2px;
  position: relative;
  width: 100%;
}
.grid-gantt-items-p p {
  font-size: 12px;
  color: #5c5c5c;
  margin-bottom: 0px;
  position: sticky;
  left: 280px;
}

.grid-gantt-items-dia {
  grid-area: 1 / auto;
  display: inline-flex;
  text-align: center;
  align-content: center;
  flex-direction: column;
  color: #5c5c5c;
  font-size: 12px;
  padding: 4px 0 9px;
  border: 1px solid #d9d9d9;
  border-right: 0;
  border-left: 0;
}
.grid-gantt::-webkit-scrollbar {
  height: 8px;
}

.grid-gantt::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 30px;
}
.legenda {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 15px;
  font-size: 12px;
}
.legenda div {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.legenda div::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 20px;
  margin-right: 5px;
}
.emDia::before {
  background: #4aae51;
}
.atencao::before {
  background: #f9a825;
}
.emAtraso::before {
  background: #d22e2e;
}
.emDia {
  color: #4aae51;
}
.atencao {
  color: #f9a825;
}
.emAtraso {
  color: #d22e2e;
}
.gradiente {
  z-index: 1;
  position: absolute;
  right: 1px;
  height: calc(100% - 110px);
}
</style>