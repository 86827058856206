var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-gantt"},[_c('div',{},[_vm._m(0),_c('div',{staticClass:"grid-ganttScroll"},[_c('img',{staticClass:"gradiente",attrs:{"src":require("@/assets/img/gradient-branco.png"),"alt":""}}),_c('div',{ref:"refGridGantt",staticClass:"grid-gantt",attrs:{"id":"scrollHorizontal"}},[_vm._l((_vm.gantt),function(gant){return _vm._l((gant.qtdDia),function(dia,i){return _c('span',{key:gant.nome + i,staticClass:"grid-gantt-items-dia"},[_vm._v(" "+_vm._s(dia)+" "+_vm._s(gant.nome)+" ")])})}),_vm._l((_vm.projectsTasks),function(task,index){return _c('PanelGantChartTasks',{key:index,ref:`refTask${index}`,refInFor:true,staticClass:"grid-gantt-items-tarefas",style:({
            'grid-column': `${_vm.getDayOfYear(task.dataInicio)}/${
              _vm.getDayOfYear(task.dataFim) + 1
            }`,
          }),attrs:{"task":task,"index":index}})})],2),_vm._m(1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gantt-titulo"},[_c('h2',[_vm._v("Visão geral do desenvolvimento")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legenda"},[_vm._v(" Legenda: "),_c('div',{staticClass:"emDia"},[_vm._v("Em dia")]),_c('div',{staticClass:"atencao"},[_vm._v("Atenção")]),_c('div',{staticClass:"emAtraso"},[_vm._v("Em atraso")])])
}]

export { render, staticRenderFns }