<template>
   <div :style="{color: colorEquipe, borderColor: colorEquipe}">
      <div :style="{backgroundColor: colorLegenda}" class="legenda" />  
      <p><strong>{{ task.setorResponsavel.nome }} |</strong> {{ task.nome }}</p>
   </div>
</template>

<script>
export default {
  props: {
    task: {
      type: Object,
    }
  },
  data() {
    return {
      equipe: {
        cliente: '#7678ED',
        implantacao: '#F35B04',
        design: '#EF476F',
        front: '#3E4A6A',
        back: '#118AB2',
        seo: '#708D81',
        dataScience: '#57CC99'
      },
      legenda: {
        emDia: '#4AAE51',
        atencao: '#F9A825',
        emAtraso: '#D22E2E'
      }
    }
  },
  computed: {
    colorEquipe() {
      switch (this.task.setorResponsavel.nome) {
        case 'Cliente':
          return this.equipe.cliente;
        case 'Implantação':
          return this.equipe.implantacao;
        case 'Design':
          return this.equipe.design;
        case 'Front':
          return this.equipe.front;
        case 'Back':
          return this.equipe.back;
        case 'SEO':
          return this.equipe.seo;
        case 'Data Science':
          return this.equipe.dataScience;
        default:
      }
      return '#000';
    },
    colorLegenda() {
      switch (this.task.andamento) {
        case 'em_dia':
          return this.legenda.emDia;
        case 'atenção':
          return this.legenda.atencao;
        case 'em_atraso':
          return this.legenda.emAtraso;
        default:
      }
      return '#000';
    }
  },
}
</script>

<style scoped>
.legenda {
  width: 8px;
  height: 8px;
  border-radius: 20px;
}
</style>
<style>
.grid-gantt-items-tarefas p {
  margin-bottom: 0px;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
</style>