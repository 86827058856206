<template>
  <div class="container-notificacoes">
    <h2 class="notificacoes-titulo">Notificações</h2>
    <div class="notificacoes-items-flex">
      <div class="linhaDoTempo" />
      <div class="notificacoes-items">
        <div
          class="notificacoes-item"
          v-for="projectUpdate in projectUpdates"
          :key="projectUpdate.id"
        >
          <div class="data">
            <span class="mes">
              <!-- 22 DEZ -->
              {{ projectUpdate.createdAt | shortMonthDay }}
            </span>
            <span class="horario">
              {{ projectUpdate.createdAt | shortHour }}
            </span>
          </div>

          <div
            class="setor"
            :style="{ background: colorEquipe(projectUpdate.autor.nome) }"
          >
            {{ projectUpdate.autor.nome }}
          </div>
          <p v-html="projectUpdate.conteudo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notificacoes: [
        {
          id: 1,
          texto:
            "Ganhe tempo e garanta sua data de Go-Live! Você já pode nos enviar informações para a sua migração. <a href='#'>Clique aqui e saiba mais.</a> Ganhe tempo e garanta sua data de Go-Live! Você já pode nos enviar informações para a sua migração. <a href='#'>Clique aqui e saiba mais.</a>",
          autor: {
            id: 1,
            nome: "Cliente",
          },
        },
        {
          id: 2,
          texto: "Novos serviços e parceiros foram adicionados ao seu projeto!",
          autor: {
            id: 1,
            nome: "Implantação",
          },
        },
        {
          id: 3,
          texto: "Novos serviços e parceiros foram adicionados ao seu projeto!",
          autor: {
            id: 1,
            nome: "Design",
          },
        },
        {
          id: 4,
          texto:
            "Ganhe tempo e garanta sua data de Go-Live! Você já pode nos enviar informações para a sua migração. <a href='#'>Clique aqui e saiba mais.</a>",
          autor: {
            id: 1,
            nome: "Cliente",
          },
        },
        {
          id: 5,
          texto: "Novos serviços e parceiros foram adicionados ao seu projeto!",
          autor: {
            id: 1,
            nome: "Implantação",
          },
        },
        {
          id: 6,
          texto: "Novos serviços e parceiros foram adicionados ao seu projeto!",
          autor: {
            id: 1,
            nome: "Design",
          },
        },
        {
          id: 7,
          texto:
            "Ganhe tempo e garanta sua data de Go-Live! Você já pode nos enviar informações para a sua migração. <a href='#'>Clique aqui e saiba mais.</a>",
          autor: {
            id: 1,
            nome: "Cliente",
          },
        },
        {
          id: 8,
          texto: "Novos serviços e parceiros foram adicionados ao seu projeto!",
          autor: {
            id: 1,
            nome: "Implantação",
          },
        },
        {
          id: 9,
          texto: "Novos serviços e parceiros foram adicionados ao seu projeto!",
          autor: {
            id: 1,
            nome: "Design",
          },
        },
        {
          id: 10,
          texto:
            "Ganhe tempo e garanta sua data de Go-Live! Você já pode nos enviar informações para a sua migração. <a href='#'>Clique aqui e saiba mais.</a>",
          autor: {
            id: 1,
            nome: "Cliente",
          },
        },
        {
          id: 11,
          texto: "Novos serviços e parceiros foram adicionados ao seu projeto!",
          autor: {
            id: 1,
            nome: "Implantação",
          },
        },
        {
          id: 12,
          texto: "Novos serviços e parceiros foram adicionados ao seu projeto!",
          autor: {
            id: 1,
            nome: "Design",
          },
        },
      ],
      equipe: {
        cliente: "#7678ED",
        implantacao: "#F35B04",
        design: "#EF476F",
        front: "#3E4A6A",
        back: "#118AB2",
        seo: "#708D81",
        dataScience: "#57CC99",
        default: "#57CC99",
      },
    };
  },
  filters: {
    shortMonthDay(datetime) {
      const [ date ] = datetime.split(' ');
      const shortMonth = new Date(date).toLocaleString('default', { month: 'short' }).toUpperCase();
      return `${date.split('-')[2]} ${shortMonth}`;
    },
    shortHour(datetime) {
      const [ hour, minute ] = datetime.split(' ')[1].split(':');
      return `${hour}h${minute}`;
    }
  },  
  computed: {
    projectUpdates() {
      return this.$store.state.projectModule.projectUpdates;
    }
  },  
  methods: {
    colorEquipe(setor) {
      let color;
      switch (setor) {
        case "Cliente":
          color = this.equipe.cliente;
          break;
        case "Implantação":
          color = this.equipe.implantacao;
          break;
        case "Design":
          color = this.equipe.design;
          break;
        case "Front":
          color = this.equipe.front;
          break;
        case "Back":
          color = this.equipe.back;
          break;
        case "SEO":
          color = this.equipe.seo;
          break;
        case "Data Science":
          color = this.equipe.dataScience;
          break;
        default:
          color = this.equipe.default;
          break;
      }

      return color;
    },
  },
};
</script>

<style scoped>
.container-notificacoes {
  height: 100%;
}
.notificacoes-titulo {
  font-size: 20px;
  color: #1c1c1c;
  font-family: "Rubik", sans-serif;
  margin-bottom: 15px;
}
.linhaDoTempo {
  min-height: 100%;
  height: 100%;
  width: 1px;
  background: #c8c8c8;
  position: sticky;
  top: 0;
}
.notificacoes-items-flex {
  display: flex;
  height: 100%;
  border: 1px solid #eaeaea;
  justify-content: center;
  gap: 15px;
  height: calc(1133px - 43px);
  overflow: hidden;
  overflow-y: scroll;
}
.notificacoes-item {
  border-radius: 2px;
  margin-top: 10px;
  position: relative;
  width: 240px;
  min-height: 146px;
  padding: 8px 10px;
  border: 1px solid #ececec;
}
.notificacoes-item::before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 100%;
  position: absolute;
  left: -19px;
  top: 38px;
  display: block;
  background: #c8c8c8;
}
.notificacoes-item::after {
  content: url("../../assets/img/notificacoes/polygon.svg");
  position: absolute;
  left: -9px;
  top: 31px;
}
.setor {
  min-height: 20px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  margin-bottom: 10px;
}
.notificacoes-item p {
  font-size: 11px;
  color: #3c4252;
  margin-bottom: 0;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  outline: 1px solid #d9d9d9;
  border-radius: 30px;
}
.data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.mes {
  font-size: 12px;
  color: #3c4252;
}
.horario {
  font-size: 10px;
  color: rgba(60, 66, 82, 50%);
}
</style>
<style>
.notificacoes-item a {
  color: #0077df;
  text-decoration: underline;
}
</style>