<template>
  <div>
    <div>
      <h2 class="responsavel-titulo">
        Equipe responsável
      </h2>
      <div class="containerEquipe-items">
        <div 
          v-for="(projectMember, index) in projectsTeam" 
          :key="index"
          class="responsavel-item" 
        >
          <div class="responsavel-item-foto">
            <img 
              :src="projectMember.imagem" 
              :alt="projectMember.nome"
              width="60" 
              height="60" 
            >
          </div>
          <div class="responsavel-item-perfil">
            <h3>
              {{ projectMember.nome }}
            </h3>
            <p>
              {{ projectMember.cargo }}
            </p>
            <span v-if="projectMember.contatoVisivel">
              {{ projectMember.email }}
              <button 
                @click="copyEmail($event, 'projectMember.email')"
                type="button" 
                class="btn-copy copy"
              >
                <img class="icon-email copy" src="@/assets/img/equipe-responsavel/copiar.svg" alt="Copiar e-mail">
                <img class="icon-email copied" src="@/assets/img/equipe-responsavel/copiado.svg" alt="Copiar e-mail">
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exaust: 0,
    }
  },
  computed: {
    projectsTeam() {
      return this.$store.state.projectModule.projectsTeam;
    }
  },  
  methods: {
    copyEmail({ currentTarget }, email) {
      clearTimeout(this.exaust);
      
      // É possível utilizar writeText para copiar apenas em sites HTTPS
      if (window.isSecureContext) {
        navigator.clipboard.writeText(email).then(() => {
          currentTarget.classList.toggle('copy');
          currentTarget.classList.toggle('copied');
          this.exaust = setTimeout(() => {
            currentTarget.classList.toggle('copy');
            currentTarget.classList.toggle('copied');
          }, 500);
        });
      }
    }
  },
}
</script>

<style scoped>
.responsavel-titulo {
  font-size: 20px;
  color: #1c1c1c;
  font-family: "Rubik", sans-serif;
  margin-bottom: 15px;
}
.containerEquipe-items {
  display: flex;
  flex-wrap: wrap;
  gap: 25px 35px;
  border: 1px solid #EAEAEA;
  padding: 25px;
}
.responsavel-item {
  display: flex;
}
.responsavel-item-foto {
  max-width: 60px;
  width: 100%;
  margin-right: 15px;
}
.responsavel-item-foto img {
  width: 60px;
  height: 60px;
}
.responsavel-item-perfil h3 {
  font-size: 16px;
  font-weight: bold;
  color: #3C4252;
  margin-bottom: 5px;
}
.responsavel-item-perfil p {
  font-size: 12px;
  color: #3C4252;
  margin-bottom: 13px;
}
.responsavel-item-perfil span {
  font-size: 11px;
  color: #3C4252;
}
.responsavel-item-perfil span button{
  margin-left: 10px;
}
.icon-email {
  display: none;
}
.btn-copy.copy .icon-email.copy {
  display: block;
}
.btn-copy.copied .icon-email.copied {
  display: block;
}
</style>